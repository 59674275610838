import { Author } from 'types/author';

export const getAuthorLink = (author: any): string =>
  author?.slug !== ''
    ? `/user/${author?.slug}`
    : `/user/${author?.newSlug ?? 'admin'}`;

export const getAuthorName = (author: Author, message, locale: string) => {
  // Edge case in the API for UA site version. Why?!
  // TODO: Find this in the API and change it to an array of localized names
  // TODO: (or take locale as request param and localize the name on the server), not this crap!
  if (locale === 'ua' && author.userNameUk && author.userNameUk.trim()) {
    return author.userNameUk;
  }

  if (author.firstName || author.lastName) {
    return [author.firstName, author.lastName].filter((i) => !!i).join(' ');
  }

  return message;
};

export const getAuthorPostsCount = (author: Author, locale) => {
  const convertedLang = locale === 'ua' ? 'uk' : locale;
  if (author === undefined || author?.postsCounts === undefined) {
    return 0;
  }
  return author?.postsCounts[convertedLang];
};
