import cn from 'classnames';
import { format } from 'date-fns';
import truncate from 'lodash/truncate';
import { useLocale } from 'next-intl';

import styles from '../styles/authorIntro.module.scss';

import Thumbnail from '../../Thumbnail/Thumbnail';

import { ThumbnailPropTypes } from '.';

import { getAuthorLink } from '@lib/author/getters';
import { getCloudflareImageLink } from '@lib/image/getters';
import { Link } from '@lib/navigation';
import { getUserName } from '@lib/user';
import optimizeAvatarURL from 'utils/optimizeAvatarURL';

type Variant = 'tile' | 'card' | 'separate';

type Color = 'regular' | 'orange';

type Props = {
  variant?: Variant;
  color?: Color;
  author: any;
  date?: number;
  className?: string;
  truncateLength?: number;
  thumbnailSize?: ThumbnailPropTypes['size'];
};

export default function AuthorIntro({
  variant = 'tile',
  color = 'regular',
  author = {},
  date,
  className,
  truncateLength = 40,
  thumbnailSize = 'premium',
}: Props) {
  const lang = useLocale();
  const avatarUrl = getCloudflareImageLink(
    optimizeAvatarURL(author?.avatar, '55x55'),
  );

  const customFieldName = lang === 'ua' ? 'userNameUk' : undefined;

  const palette: Record<Variant, string> = {
    'tile': 'bg-white text-black lg:bg-transparent lg:text-white',
    'card': 'bg-transparent text-black',
    'separate': 'bg-transparent text-white',
  }

  const authorColor = {
    'regular': 'text-premium-500',
    'orange': 'text-orange-500',
  }

  return (
    <div className={cn('flex flex-row items-center px-6 py-2 text-xs lg:text-md justify-between', palette[variant], className)}>
      <Link href={getAuthorLink(author)}>
        <div className={cn('flex flex-row items-center')}>
          <div>
            <Thumbnail
              name={getUserName(author, customFieldName)}
              path={avatarUrl}
              className="pointer"
              size={thumbnailSize}
            />
          </div>
          <div className={'pl-2'}>
            <p className={cn('font-bold', authorColor[color])}>
              {getUserName(author, customFieldName)}
            </p>
            {author?.userCaption && (
              <p className={styles.caption}>
                {truncate(author?.userCaption, {
                  omission: '...',
                  length: truncateLength,
                })}
              </p>
            )}
          </div>
        </div>
      </Link>
      {date && (
        <div className={''}>
          {format(new Date(date), 'dd.MM.yyyy')}
        </div>
      )}
    </div>
  );
}
