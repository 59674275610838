import classNames from 'classnames';

import { AuthorIntro, CoverPhoto } from './index';

import {
  getArticleUrl,
  getLiveStatus,
  getCoverPhotoAlt,
  getPremiumStatus,
} from '@lib/article/getters';
import { getArticleImgUrl } from '@lib/image/getters';
import { Link } from '@lib/navigation';
import { ImageSize } from 'types/post';

type Props = {
  article: any;
  testId?: number;
  testString?: string;
  ratio?: '4/3' | '16/9' | '7/5';
  className?: string;
  isHeader?: boolean;
  photoSize?: ImageSize;
};

const ArticleCover = ({
  article,
  testString,
  testId = 0,
  ratio = '4/3',
  className = '',
  isHeader = false,
  photoSize = 'medium',
}: Props) => {
  const urlLocale = () => {
    if (article?.langName === 'uk') {
      return 'ua';
    }
    return article?.langName || undefined;
  };

  return (
    <>
      <div
        className={classNames('relative rounded-2xl	overflow-hidden', {
          'header-cover': isHeader,
          [className]: true,
        })}
        data-test={`article.cover.${testString ? testString + '.' : ''
          }${testId}`}
      >
        <CoverPhoto
          imgUrl={getArticleImgUrl(article, isHeader ? 'large' : photoSize)}
          isLive={getLiveStatus(article)}
          isPremium={getPremiumStatus(article)}
          alt={getCoverPhotoAlt(article)}
          imgClass="zoomable"
          ratio={ratio}
          postType={article?.type}
          url={getArticleUrl(article)}
          urlLocale={urlLocale()}
        />
        <div className="absolute bottom-0 w-full article-cover_footer">
          <Link href={getArticleUrl(article)}>
            <h2
              className="text-white xl:text-2xl font-bold m-2 xl:mb-0"
              data-test={`article.cover.title${testId}`}
            >
              {article?.title}
            </h2>
          </Link>
          <div className="hidden xl:block">
            <AuthorIntro
              author={article?.author}
              date={article?.dateGmt}
            />
          </div>
          <div className="block xl:hidden">
            <AuthorIntro
              truncateLength={isHeader ? 70 : 40}
              author={article?.author}
              date={article?.dateGmt}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ArticleCover;
