interface User {
  name: string;
  firstName?: string;
  lastName?: string;
  userNameUk?: string;
  userDeleted?: boolean;
  slug?: string;
}

export const getUserName = (
  user: User | null,
  customField?: string,
): string => {
  if (!user) return 'deleted user';
  if (user?.userDeleted) return 'user deleted';
  if (
    customField !== undefined &&
    user?.[customField] !== undefined &&
    user?.[customField] !== null &&
    user?.[customField]?.trim() !== ''
  )
    return user?.[customField];
  if (user?.name !== undefined) return user?.name;
  if (user?.firstName !== undefined && user?.lastName !== undefined)
    return `${user.firstName} ${user.lastName}`;
  return 'guest';
};
