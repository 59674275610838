import CoverPhoto from './CoverPhoto';

import {
  getArticleUrl,
  getCoverPhotoAlt,
  getLiveStatus,
  getPremiumStatus,
} from '@lib/article/getters';
import { getArticleImgUrl } from '@lib/image/getters';
import { Link } from '@lib/navigation';

const ArticleIntroView = ({ article, testId }: any) => {
  return (
    <Link href={getArticleUrl(article)}>
      <div
        className="article-intro-view"
        data-test={`article.intro.view.${testId}`}
      >
        <CoverPhoto
          imgUrl={getArticleImgUrl(article, 'thumb')}
          isLive={getLiveStatus(article)}
          isPremium={getPremiumStatus(article)}
          compactBadge={true}
          alt={getCoverPhotoAlt(article)}
          className="article-intro-view_photo "
          imgClass="zoomable"
          isDarken={false}
          postType={article.type ?? article.postType}
        />

        <div
          data-test={`article.intro.view.title.${testId}`}
          className="article-intro-view_text"
        >
          {article.title || article.postTitle}
        </div>
      </div>
    </Link>
  );
};

export default ArticleIntroView;
