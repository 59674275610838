import cn from 'classnames';

import LiveBadge from './badges/LiveBadge';
import PremiumBadge from './badges/PremiumBadge';

import { ArticleBadge } from '@lib/article/getters';
import { Breakpoint } from '@hooks/useBreakpoints';

const badgeComponents: Record<ArticleBadge, any> = {
  premium: PremiumBadge,
  live: LiveBadge,
};

interface ArticleBadgesProps {
  className?: string;
  absolute: boolean;
  showBadges?: Breakpoint | 'always';
  badges: ArticleBadge[];
}

export default function ArticleBadges({
  className,
  absolute,
  showBadges,
  badges,
}: ArticleBadgesProps) {

  const getAbsoluteClassName = () => {
    return (absolute) ? 'absolute left-2 right-2 top-2' : '';
  }

  const getWrapperClassName = () => {
    switch (showBadges) {
      case 'sm':
        return 'sm:flex-row sm:items-start';
      case 'md':
        return 'md:flex-row md:items-start';
      case 'lg':
        return 'lg:flex-row lg:items-start';
      case 'xl':
        return 'xl:flex-row xl:items-start';
      case 'xxl':
        return 'xxl:flex-row xxl:items-start';
      case 'always':
        return 'flex-row items-start';
      default:
        return '';
    }
  };

  return (
    <div
      className={cn(
        'gap-2 flex flex-wrap max-h-[30px] overflow-hidden',
        getAbsoluteClassName(),
        getWrapperClassName(),
        className,
      )}
    >
      {badges.map((badge) => {
        const Badge = badgeComponents[badge];
        return <Badge
          full={showBadges}
          key={badge}
        />;
      })}
    </div>
  );
}
