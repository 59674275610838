const optimizeAvatarURL = (avatar: any = null, size: string) => {
  const checkAvatarUrl =
    avatar === null
      ? 'null'
      : avatar.indexOf('cdn-trans.info/uploads') > -1
      ? 'cloudflare'
      : 'social';

  switch (checkAvatarUrl) {
    case 'cloudflare': {
      // check if URL of photo is cloudfront
      const parts =
        avatar !== null && avatar.indexOf('cdn-trans.info/uploads') > -1
          ? avatar.split('.')
          : '';
      const extension = avatar !== null ? parts.pop() : '';
      const file_url = avatar !== null ? parts.join('.') : '';
      return file_url + '-' + size + '.' + extension;
    }
    case 'social':
      return avatar.replace('type=large', 'type=small');
    default:
      return avatar;
  }
};

export default optimizeAvatarURL;
