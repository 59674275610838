'use client';
import cn from 'classnames';
import AspectRatio from 'react-aspect-ratio';

import ArticleBadges from '@components/Article/atoms/ArticleBadges';
import ArticleImageOverlay from '@components/Article/atoms/ArticleImageOverlay';
import { getArticleUrl } from '@lib/article/getters';
import { getArticleBadges } from '@lib/article/getters';
import { getArticleImgUrl } from '@lib/image/getters';
import { Link } from '@lib/navigation';
import { getArticleType } from '@utils/article/type';

type Props = {
  article: any;
  testId?: number;
};

const ArticleSimple = ({ article, testId = 0 }: Props) => {
  const imageUrl = getArticleImgUrl(article, 'medium');

  const wrapperClass = cn('overflow-hidden rounded-md xl:rounded-2xl');

  const type = getArticleType(article);

  const image = (
    <div>
      <AspectRatio ratio={'4/3'} className={wrapperClass}>
        {/* eslint-disable-next-line @next/next/no-img-element */}
        <img
          src={imageUrl}
          loading="lazy"
          className={'object-cover transition-opacity duration-500 hover:opacity-50'}
          alt={article?.title ?? ''}
        />
      </AspectRatio>
    </div>
  );

  return (
    <>
      <div className="block" data-test={`article.intro.view.${testId}`}>
        <Link href={getArticleUrl(article)}>
          <div className="flex">
            <div className="relative w-1/3">
              {image}
              <ArticleImageOverlay type={type} />
              <ArticleBadges badges={getArticleBadges(article)} showBadges="always" absolute={true} />
            </div>
            <div className="w-2/3 pl-2 xl:pl-4">
              <h2
                className="text-sm font-normal text-black xl:text-base dark:text-white"
                data-test={`article.intro.view.title.${testId}`}
              >
                {article?.title}
              </h2>
            </div>
          </div>
        </Link>
      </div>
    </>
  );
};

export default ArticleSimple;
