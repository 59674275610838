'use client';
import { memo } from 'react';
const defaultAvatar = '/static/default-avatar.png';

export type PropTypes = {
  path: string | null;
  name: string;
  className?: string;
  size?: 'small' | 'medium' | 'big' | 'premium';
  isZoomable?: boolean;
  bgColor?: string;
};
const Thumbnail = ({
  path = '',
  name = '',
  className = '',
  isZoomable = false,
  size = 'small',
  bgColor,
}: PropTypes) => {
  const ImgThumb = (
    <img
      className={'thumbnail rounded-full ' + className + ' ' + size}
      style={{ backgroundColor: bgColor }}
      data-test="thumbnail"
      src={path || defaultAvatar}
      alt={name || 'thumbnail'}
    />
  );
  return isZoomable ? (
    <div className={'thumbnail-zoomable ' + className + ' ' + size}>
      {ImgThumb}
    </div>
  ) : (
    ImgThumb
  );
};

export default memo(Thumbnail);
